var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("Contact Us")]),_c('a-divider',{staticStyle:{"margin":"10px 0","background-image":"linear-gradient(to right,  rgb(103, 179, 241),  rgb(103, 179, 241), #f6f6f6, #f6f6f6)"}}),_vm._m(1),_vm._m(2),_vm._m(3),_c('br'),_c('p',{staticClass:"title"},[_vm._v("Cite")]),_c('a-divider',{staticStyle:{"margin":"10px 0","background-image":"linear-gradient(to right,  rgb(103, 179, 241),  rgb(103, 179, 241), #f6f6f6, #f6f6f6)"}}),_vm._m(4),_vm._m(5),_vm._m(6),_c('br'),_c('p',{staticClass:"title"},[_vm._v("License")]),_c('a-divider',{staticStyle:{"margin":"10px 0","background-image":"linear-gradient(to right,  rgb(103, 179, 241),  rgb(103, 179, 241), #f6f6f6, #f6f6f6)"}}),_vm._m(7),_c('br')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticStyle:{"padding-top":"20px","color":"white"}},[_c('p',{staticStyle:{"margin":"20px 0 30px 130px","font-size":"60px"}},[_vm._v("About")]),_c('p',{staticStyle:{"margin":"0px 0 20px 130px","font-size":"30px"}},[_vm._v(" Your advice and contributions are very important to us. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("The LibCity is mainly developed and maintained by Beihang Interest Group on SmartCity ("),_c('a',{attrs:{"href":"https://www.bigcity.ai/","target":"_blank"}},[_vm._v("BIGSCITY")]),_vm._v("). The core developers of this library are "),_c('a',{attrs:{"href":"https://github.com/aptx1231","target":"_blank"}},[_vm._v("@aptx1231")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://github.com/WenMellors","target":"_blank"}},[_vm._v("@WenMellors")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Several co-developers have also participated in the reproduction of the model, the list of contributions of which is presented in the "),_c('a',{attrs:{"href":"https://github.com/LibCity/Bigscity-LibCity/blob/master/contribution_list.md","target":"_blank"}},[_vm._v("reproduction contribution list")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("If you encounter a bug or have any suggestion, please contact us by "),_c('a',{attrs:{"href":"https://github.com/LibCity/Bigscity-LibCity/issues","target":"_blank"}},[_vm._v("raising an issue (Recommended)")]),_vm._v(" or sending an email to bigscity@126.com.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("If you find LibCity useful for your research or development, please cite our "),_c('a',{attrs:{"href":"https://dl.acm.org/doi/10.1145/3474717.3483923","target":"_blank"}},[_vm._v("paper")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',[_c('p',[_vm._v("@inproceedings{10.1145/3474717.3483923,")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("author = {Wang, Jingyuan and Jiang, Jiawei and Jiang, Wenjun and Li, Chao and Zhao, Wayne Xin},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("title = {LibCity: An Open Library for Traffic Prediction},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("year = {2021},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("isbn = {9781450386647},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("publisher = {Association for Computing Machinery},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("address = {New York, NY, USA},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("url = {https://doi.org/10.1145/3474717.3483923},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("doi = {10.1145/3474717.3483923},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("booktitle = {Proceedings of the 29th International Conference on Advances in Geographic Information Systems},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("pages = {145–148},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("numpages = {4},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("keywords = {Reproducibility, Traffic Prediction, Spatial-temporal System},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("location = {Beijing, China},")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("series = {SIGSPATIAL '21}")]),_c('p',[_vm._v("}")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',[_c('p',[_vm._v("Jingyuan Wang, Jiawei Jiang, Wenjun Jiang, Chao Li, and Wayne Xin Zhao. 2021. LibCity: An Open Library for Traffic Prediction. In "),_c('i',[_vm._v("Proceedings of the 29th International Conference on Advances in Geographic Information Systems")]),_vm._v(" ("),_c('i',[_vm._v("SIGSPATIAL '21")]),_vm._v("). Association for Computing Machinery, New York, NY, USA, 145–148. DOI:https://doi.org/10.1145/3474717.3483923")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("LibCity uses "),_c('a',{attrs:{"href":"https://github.com/LibCity/Bigscity-LibCity/blob/master/LICENSE.txt","target":"_blank"}},[_vm._v("Apache License 2.0")]),_vm._v(".")])
}]

export { render, staticRenderFns }